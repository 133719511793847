* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  font-family: 'Nunito Sans', sans-serif;
  background-color: #02030d;
  color: #d9d9d9;
  font-size: 13px;
  font-weight: 300;
}

a {
  text-decoration: none;
  color: inherit;
}

p {
  margin: 10px 0 10px;
}

.landing p {
  margin: 20px 0 20px;
}

a:hover {
  color: rgb(102, 114, 180);
}

#container {
  display: flex;
  margin: 40px;
}

header {
  width: 20%;
  padding-right: 10px;
}

#navbar div {
  margin-bottom: 20px;
}

#my-name {
  font-size: .6em;
}

/* #chinese {
  letter-spacing: 1px;
  font-size: 1.2em;
} */

/* ==================== MAIN CONTENT SECTION ==================== */

#content {
  width: 70%;
}

.landing-img {
  width: 30%;
  margin-bottom: 20px;
  margin-top: 20px;
}

.main-img {
  width:100%;
}

#photocredit {
  font-size: .8em;
  margin-top: 0;
  text-align: end;
  margin-bottom: 20px;
}

#specs {
  margin: 10px 0 0;
}

#details {
  margin: 0 0 10px;
}

#link {
  font-weight: 700;
  text-decoration: underline;
  color: #b19f8b;
}

.title {
  margin: 0 0 20px;
  font-weight: 300;
}

iframe {
  width: 100%;
  margin-top: 30px;
}

.current-page {
  color: rgb(102, 114, 180);
}

.active {
  color: rgb(102, 114, 180);
}

.hidden {
  display: none;
}

.image-bar {
  height: 50px;
  max-width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-bar img {
  height: 100%;
  margin: 7px;
}

@media only screen and (max-width: 700px) {

  #container {
      display: block;
      margin: 20px;
      position: relative;
  }

  #films {
      display: none;
  }

  #info {
      display: none;
  }
  #content {
      width: 100%;
  }
  header {
      width: 40%;
  }
  #navbar-mobile {
      display: block;
      text-align: right;
      position: absolute;
      top: 0;
      right: 0;
      background-color: #02030d;
      transition: 1s;
      animation: forwards;
      
  }
  #nav-button-mobile {
      color: #d9d9d9;
      align-items: center;
      font-size: 2em;
      width: 45px;
      height: 45px;
      background-color: #02030d;;
      border: none;
      border-radius: 3px;
  }
  #films-mobile {
      padding: 20px 20px 0;
  }

  #info-mobile {
      padding: 0 20px 20px;  
      margin-top: 35px;
  }

  #films-mobile p {
      margin: 15px 0 15px;
  }

  .image-bar img {
    height: 75%; 
  }

  .landing-img {
    width:65%;
  }

}
